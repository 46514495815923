/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function(str, newStr){

		// If a regex pattern
		if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
			return this.replace(str, newStr);
		}

		// If a string
		return this.replace(new RegExp(str, 'g'), newStr);

	};
}

export const sanitize = function(str) {
 return (str
.normalize("NFD")
.replace(/[\u0300-\u036f]/g, "")
.replace(/\s{1,}/gi, '_')
.toLowerCase())
}

export const sanitizeArray = function(arr) {
    if(!arr) return [];
    const sarr = arr.map((i) => { return sanitize(i) });
    return sarr;
}

export const equalArray = function(arr1, arr2) {
    if(!arr1 || !arr2) return false;
    if(arr1.length !== arr2.length) return false;
    return arr1.every((i, index) => {
        return i === arr2[index]
    });
}

export const replaceAll = function(str, search, replace) {
    return str.replaceAll(search, replace);
} 