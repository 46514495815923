import { equalArray, sanitizeArray, sanitize } from './index';

function getCategoryItems(items, lang) {
    return items
        .filter((i) => {
            return i.type === 'directory';
        })
        .map((i) => {
            i.href = '\/' + sanitize(i.path);
            return i;
        }).map((i) => {
            const name = i.genericName.slice(0,-5) + '.png';
            i.thumbnailHref = i.thumbnail;
            if(lang) {
                i.href = `/${lang.toLowerCase()}${i.href}`;
            }
            return i;
        });
}

function getFileItems(items) {
    return items
        .filter((i) => {
            return i.type === 'file' && i.extension !== '.txt';
        })
        .map((f) => {
            const root = f.path.split("\\").slice(1,-1).map((i) => encodeURI(i)).join("\\");
            f.href = `/mediatheque-files/${root}/${f.name}`;
            return f;
        })
        .map((f) => {
            const name = f.name.slice(0,-3) + 'png';
            f.thumbnailHref = encodeURI(`/mediatheque-files-thumbnail/${name}`).replace(/'/g, "%27");
            return f;
        });
}

function getVideoItems(items) {
    
    return items
        .filter((i) => {
            return i.type === 'file' && i.mediaType === 'file' && i.extension === '.txt' && i.link.length > 0;
        })
        .map((i) => {
            const root = i.path.split("\\").slice(1,-1).map((i) => encodeURI(i)).join("\\");
            i.href = `/mediatheque-files/${root}/${i.name}`;
            return i;
        })
        .map((i) => {
            const name = i.name.slice(0,-3) + 'png';
            i.thumbnailHref = `/mediatheque-files-thumbnail/${name}`;
            return i;
        });
}

function getLangItems(items, lang = 'FR') {
    return items.filter((item) => {
        if(!item?.lang?.split) return;
        const langArr = item.lang.split(',').map((e) => e.trim());
        return langArr.includes(lang.trim());
    });
}

function filterSearch(tree, searchArr) {
    //console.log({ searchArr });
    return tree.filter((item) => {
        const str = `${item.path} ${item.name} ${item.title} ${item.tag}`.toLowerCase();
        return searchArr.some((s) => {
            return str.includes(s);
        });
    });
}

function extractItems(routerQuery, tree, lang, search = '') {

    let sanitizedPath = sanitizeArray(routerQuery);
    let items = [];

    if(!sanitizedPath) return items;
    if(sanitizedPath.length === 0) return items;


    //filter only for given lang
    tree = getLangItems(tree, lang);

    let urlQuery = sanitizedPath[0].split('=');

    //filter for live search
    if(search.length >= 3) {
        //console.log("===> inside search");
        return filterSearch(tree, [search]);
    }
    
    //filter for URL search
    if(urlQuery[0] === 'search') {
        const searchArrayURL = urlQuery[1].toLowerCase().split(',');
        return filterSearch(tree, searchArrayURL);
    }

    //filter for tag URL
    if(urlQuery[0] === 'tags') {
        const tagArr = urlQuery[1].toLowerCase().split(',');
        items = tree.filter((item) => {
            const tagArrItem = sanitizeArray(item.tag
                .toLowerCase()
                .split(',')
                .map((i) => i.trim()));
                
            return tagArrItem.some((t) => {
                const tbis = t.replace('²','').replace('+','');
                return tagArr.includes(t) || tagArr.includes(tbis);
            })
        });
        return items;
    }

    //filter for path URL
    items = tree.filter((i) => {
        let itemPath = sanitizeArray(i.path.split("\\").slice(0, -1));
        return equalArray(itemPath, sanitizedPath);
    });

    return items;
}

export function getSortedItems(routerQuery, tree, lang, search) {
    const items = extractItems(routerQuery, tree, lang, search);
    const categoryItems = getCategoryItems(items, lang);

    console.log({ categoryItems });

    const fileItems = getFileItems(items);
    const videoItems = getVideoItems(items);
    return { items, categoryItems, fileItems, videoItems };
}