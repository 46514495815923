import React from 'react';
import { Link } from "react-router-dom";

function addURL(path) {
  let url = window.location.href;
  let endIndex = url.indexOf('/page_mediatheque/') + '/page_mediatheque/'.length;
  let result = url.substring(0, endIndex);
  let remaining = url.substring(endIndex);
  let resultArray = remaining.split('/');
  let rootURL = result.replace(window.location.origin, '');
  path = path.map((p, index) => {
    rootURL += resultArray[index] + '/';
    return { label: p, url: rootURL }
  });
  return path;
}

function translate(p, tree, lang) {
  let i = tree.find(e => { return e.name.includes(`${p}_${lang.toUpperCase()}`) && e.type === "directory" });
  if(!i) return p;
  return i.title;
}

export default function Breadcrumb({ path, tree }) {

  const lang = localStorage.getItem('DENTAL_APP_LANG');
  path = addURL(path);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to={`/${lang}`} target="_pg_blank"><i className="fa fa-home" /></Link>
        </li>
        {
          path.map((i) => {
            return (<li key={i.label} className="breadcrumb-item">
              <Link to={i.url} target="_pg_blank">{translate(i.label, tree, lang)}</Link>
            </li>)
          })
        }
      </ol>
    </nav>
  )
}