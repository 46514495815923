import React, { useState, useEffect } from 'react';
import logo from './react.svg';
import tree from './data/tree.json';
import Mediatheque from './pages/mediatheque/Mediatheque';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import './styles/globals.css';

const langList =['EN','FR','IT','ES'];

var loadJS = function(url, callback, location = document.body){
  var scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.onload = callback;
  scriptTag.onreadystatechange = callback;
  location.appendChild(scriptTag);
};

export default function Home() {

  const location = useLocation();
  let routerQuery = [];
  let [itemList, setItemList] = useState([]);

  //let codeLang = 'FR';
  let codeLang = (localStorage.getItem('DENTAL_APP_LANG') || 'fr').toUpperCase();

  useEffect(() => {
    if(sessionStorage.getItem('dataMedia')) {
      setItemList(JSON.parse(sessionStorage.getItem('dataMedia')));
      return;
    }
    loadJS('https://script.google.com/macros/s/AKfycbw95sswTlXgSCZQkTKtDnHROirap3qkDowrjHJfwT5W5rqSPL-2t8j53Qc4M9i10r8I/exec?path=main&name=dataMedia', () => {
      setItemList(window.dataMedia);
      sessionStorage.setItem('dataMedia', JSON.stringify(window.dataMedia));
    });
  }, []);
  
  if (['/fr/page_mediatheque/categorie_aide_a_l\'utilisation/sous-categorie_bibliotheques_numeriques',
    '/page_mediatheque/categorie_aide_a_l\'utilisation/sous-categorie_bibliotheques_numeriques']
    .includes(location?.pathname)) {
    window.location.href = "https://www.lyra.dental/bibliotheques-numeriques/";
  }

  if(location.pathname.includes('/mediatheque-files/')) {
    window.location.href = `https://filedn.eu/lud6s3pANWJyeBF0LhT0zau/Drive%20LYRA/m%C3%A9diath%C3%A8que/Arborescence/${location.pathname.replace('/mediatheque-files/', '')}`;
  }

  if (location.pathname) {
    routerQuery = location.pathname
      .split(/(\/|\\)/).filter((i) => (!!i && i !== '' && i !== "\/" && i !== "\\"));
  }

  if(langList.includes(routerQuery[0].toUpperCase())) {
    codeLang = routerQuery[0].toUpperCase();
    localStorage.setItem('DENTAL_APP_LANG', codeLang.toLowerCase());
    routerQuery = routerQuery.slice(1);
  }

  return (
    <Mediatheque routerQuery={routerQuery} tree={itemList} lang={codeLang}></Mediatheque>
  );
}
