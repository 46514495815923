import React from 'react';
import { Link } from 'react-router-dom';

export default function TagBreadcrumb({ tags }) {

  tags = tags.split(','); 

  //console.log({ path });
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" target="_pg_blank"><i className="fa fa-home" /></Link>
        </li>
        {
          tags.map((t) => {
            return (<li key={t} className="breadcrumb-item">
              <Link to={`/tags=${t}`} target="_pg_blank">{`#${t} `}</Link>
            </li>)
          })
        }
      </ol>
    </nav>
  )
}