import React from 'react';
import { Route, Switch, Redirect, useLocation, useParams } from 'react-router-dom';
import Home from './Home';
import './App.css';
import './styles/bootstrap.css';
import './styles/css-used.css';
import './styles/from-lyra.css';
import './styles/header.css';
import './styles/footer.css';
import './styles/from-other.css';
import './styles/starter-template.css';


const App = () => {

const lang = localStorage.getItem('DENTAL_APP_LANG') || 'fr';

return (
  <Switch>
    <Route exact path="/">
      <Redirect to={`/${lang}/page_mediatheque`} />
    </Route>
    <Route exact path="/en">
      <Redirect to="/en/page_mediatheque" />
    </Route>
    <Route exact path="/es">
      <Redirect to="/es/page_mediatheque" />
    </Route>
    <Route exact path="/it">
      <Redirect to="/it/page_mediatheque" />
    </Route>
    <Route exact path="/fr">
      <Redirect to="/fr/page_mediatheque" />
    </Route>
    <Route path="/" component={Home} />
  </Switch>
);
}

export default App;
