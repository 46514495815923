import React, { useState, useEffect } from 'react';
import jwt from 'jsonwebtoken'
import { File } from '../abstractComponent/File';
import { Category } from '../abstractComponent/Category';
import { Video } from '../abstractComponent/Video';
import { getSortedItems } from '../helpers/filter';

import PageHeader from '../pageComponent/PageHeader';
import Bandeau from '../pageComponent/Bandeau';
import Breadcrumb from '../pageComponent/Breadcrumb';
import TagBreadcrumb from '../pageComponent/TagBreadcrumb';
import SearchBreadcrumb from '../pageComponent/SearchBreadcrumb';
import Footer from '../pageComponent/Footer';
import './Mediatheque.css';


export default function Mediatheque({ routerQuery, tree, lang = 'EN' }) {

    //const router = useRouter();
    let [search, setSearch] = useState('');
    let [htmlContent, setHtmlContent] = useState('')
    let tagURL = null;

    window.searchDentalApp = setSearch;

    useEffect(() => {
        function handleLoad() {
            const cookieDomain = window.location.hostname.includes('localhost') ? '' : '.lyraetk.com';
            const JWT = Cookies.get('DENTAL_APP_AUTH', { domain: cookieDomain });            
            if (!JWT) {
                const html = window.getLyraEtkHeader({ user: {}, displaySearch: true, langList: window?.DENTAL_APP_LANG_LIST || ['fr'] });
                setHtmlContent(html);
            } else {
                const { name, surname } = jwt.decode(JWT);
                const html = window.getLyraEtkHeader({ user: { name, surname }, displaySearch: true, langList: window?.DENTAL_APP_LANG_LIST || ['fr'] });
                setHtmlContent(html);
            }
        }
        
        // Si la page est déjà chargée, appelle la fonction directement 
        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            // Sinon, ajoute un écouteur d'événements qui l'appellera une fois la page chargée.
            window.addEventListener('load', handleLoad);
        }
    
        // Nettoie l'écouteur d'événements au retour
        return () => window.removeEventListener('load', handleLoad);
    }, []);

    //check if its a tags URL
    if (routerQuery[0]) {
        if (routerQuery[0].slice(0, 5) === 'tags=') {
            tagURL = routerQuery[0].slice(5);
        }
    }

    //extract items according to types of files / link 
    const { items, categoryItems, fileItems, videoItems } = getSortedItems(routerQuery, tree, lang, search);

    let breadcrumbPath = items[0] ? items[0].path : '';
    breadcrumbPath = breadcrumbPath.split('\\').slice(1, -1) || [];
    breadcrumbPath = breadcrumbPath.map((i) => {
        return i.split('_')[1];
    });

    const setSearchTerm = function (e) {
        setSearch(e.target.value.toLowerCase());
    }

    useEffect(() => {
        setSearch('');
    }, [routerQuery]);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            <Bandeau items={items} lang={lang} search={search} setSearch={setSearchTerm}></Bandeau>
            {search.length > 2 && <SearchBreadcrumb search={search}></SearchBreadcrumb>}
            {search.length <= 2 && tagURL && <TagBreadcrumb tags={tagURL}></TagBreadcrumb>}
            {search.length <= 2 && !tagURL && <Breadcrumb tree={tree} path={breadcrumbPath}></Breadcrumb>}

            <div className="container">
                {!tree.length && <div style={{ textAlign: 'center', margin: '50px' }}><div>Chargement de la médiathèque <br></br><br></br> <div className="lds-roller"><div /><div /><div /><div /><div /><div /><div /><div /></div> </div></div>}
                {(!!categoryItems.length || !!fileItems.length || !!videoItems.length) && routerQuery.length > 1 && 
                <div onClick={() => { history.back(); }} style={{ 
                margin: '20px 0px -5px 0px', 
                cursor: 'pointer', 
                display: 'inline-block',
                backgroundColor: '#dddddd',
                padding: '5px',
                borderRadius: '2px'
                }}><Back></Back></div>}
                {categoryItems.length ? <div className="vignettes-container"><Category items={categoryItems} lang={lang}></Category></div> : null}
                {fileItems.length ? <div className="vignettes-container"><File items={fileItems} lang={lang}></File></div> : null}
                {videoItems.length ? <div className="vignettes-container"><Video items={videoItems} lang={lang}></Video></div> : null}
            </div>
            <Footer lang={lang}></Footer>
        </>
    )
}


const Back = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-arrow-back-up"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 14l-4 -4l4 -4" />
        <path d="M5 10h11a4 4 0 1 1 0 8h-1" />
    </svg>
}


