import React from 'react';
import { Link } from 'react-router-dom';


export default function FileDetail({ items, title }) {
///mediatheque-files/ => https://filedn.eu/lud6s3pANWJyeBF0LhT0zau/Drive%20LYRA/m%C3%A9diath%C3%A8que/Arborescence/
//https://filedn.eu/lud6s3pANWJyeBF0LhT0zau/Drive%20LYRA/m%C3%A9diath%C3%A8que/Image%20miniature/1_CAS_CLINIQUE_GMMORELLO.png
//http://localhost:3000/mediatheque-files-thumbnail/Les_apports_de_la_CFAO_dans_le_traitement_implantaire.png
    return (
        <>
            {
                items.map((i, index) => {     
                    
                    if(i.hide === true || i.hide === "true") return null;
                    if((i.loggedin === true || i.loggedin === "true") && !window?.DENTAL_APP_USER?.id) {
                        return null
                    }
                    
                    i.href = i.href.replace('/mediatheque-files/', 'https://filedn.eu/lud6s3pANWJyeBF0LhT0zau/Drive%20LYRA/m%C3%A9diath%C3%A8que/Arborescence/');
                    i.href = i.href.replaceAll('+','%2B');
                    i.thumbnailHref = i.thumbnailHref.replace('/mediatheque-files-thumbnail/','https://filedn.eu/lud6s3pANWJyeBF0LhT0zau/Drive%20LYRA/m%C3%A9diath%C3%A8que/Image%20miniature/');
                    //console.log(i.href);
                    return (
                        <a href={i.href} target="_blank" key={i.name} className="vignette-image">
                            <img className="thumbnail-image" src={i.thumbnailHref} />
                            <span>
                                {i.title}
                            </span>
                        </a>)
                })
            }
            <div className="title-vignette-container">{title}</div>
        </>)
}