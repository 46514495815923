import React, { useState, useEffect } from 'react';

export default function Footer({ lang }) {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        function handleLoad() {
            let html = '';
            if(window?.getLyraEtkReassurance && window.LYRA_ETK_DATA) {
                html += window.getLyraEtkReassurance();
            }
            if(window?.getLyraEtkContactBlock && window.LYRA_ETK_DATA) {
                html += window.getLyraEtkContactBlock();
            }
            if(window?.getLyraEtkFooter && window.LYRA_ETK_DATA) {
                html += window.getLyraEtkFooter();
            }
            setHtmlContent(html);
        }

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => window.removeEventListener('load', handleLoad);
    }, []);

    lang = lang.toLowerCase();

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
}