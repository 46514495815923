import React from 'react';
import FileDetail from '../components/FileDetail';
import { translator } from '../helpers/translator';



export function File({ items, lang }) {
    const sectionTitle = translator('files', lang);
    if (!items.length) return null;
    return (<FileDetail items={items} title={sectionTitle}></FileDetail>)
}