import React from 'react';
import { Link } from 'react-router-dom';

export default function SearchBreadcrumb({ search }) {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/" target="_pg_blank"><i className="fa fa-home" /></Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/" target="_pg_blank">{`recherche: ${search}`}</Link>
        </li>
      </ol>
    </nav>
  )
}