import React from 'react';
import ReactPlayer from 'react-player'


export function VideoDetail({ items, title }) {
    return (<>
        {
            items.map((i) => {

                if(i.hide === true || i.hide === "true") return null;
                if((i.loggedin === true || i.loggedin === "true") && !window?.DENTAL_APP_USER?.id) {
                    return null;
                }

                const link = i.link.length ? i.link : i.href;
                return (
                    <a className="vignette-video">
                        <div key={i.name} className="video-wrapper">
                        <ReactPlayer url={link} controls={true}/>
                        <span className="video-title">{i.title}</span>
                        </div>
                    </a>)
            })
        }
        <div className="title-vignette-container">{title}</div>
    </>)
}