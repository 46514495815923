import translation from '../../data/translation.json';

export const translator = (key, lang) => {

    console.log(key, lang);

    const item = translation.find((e) => {
        return e.key === key;
    });

    console.log(item);

    if(!item) return key;
    if(!item[lang]) return item['EN'] || item['FR'] || key;
    return item[lang];
} 